// extracted by mini-css-extract-plugin
export const wrapper = "About-module--wrapper--2GO_q";
export const peopleWrapper = "About-module--peopleWrapper--2_VIw";
export const person = "About-module--person--2vGFM";
export const sectionDivider = "About-module--sectionDivider--3xUBe";
export const content = "About-module--content--182Tj";
export const photoWrapper = "About-module--photoWrapper--3aRor";
export const corner = "About-module--corner--17OGK";
export const top = "About-module--top--1Oh5r";
export const info = "About-module--info--1E7PL";
export const title = "About-module--title--WTwmW";
export const contact = "About-module--contact--3MPSZ";
export const contentDivider = "About-module--contentDivider--7RScW";
export const text = "About-module--text--MP7uG";