import React, { useMemo } from 'react';
import { Phone, Email } from './icons';
import * as styles from './ContactLink.module.scss';
import classNames from 'classnames';
import useWindowSize from '@hooks/useWindowSize';

export default React.memo(function ContactLink({
  phone,
  email,
  href,
  className,
  shouldFormat = true,
  mobileSize = 370,
  mobileText,
  children
}) {
  const { width: windowWidth } = useWindowSize();

  const isMobile = useMemo(() => {
    return !!mobileSize && windowWidth <= mobileSize;
  }, [windowWidth, mobileSize]);

  let text = children;
  let icon = null;

  if (email) {
    text = isMobile
      ? (mobileText ?? 'Email').trim()
      : (text ?? email)?.trim();
    href = href ?? `mailto:${email}`;
    icon = <Email />;
  }

  if (phone) {
    text = isMobile
      ? (mobileText ?? 'Phone').trim()
      : (text ?? phone)?.trim() ?? '';

    const match = text
      .replace(/[^\d]/g, '')
      .match(/^(\d{3})(\d{3})(\d{4})$/);

    icon = <Phone />;

    if (match && match[0].length === 10 && match.length === 4) {
      href = href ?? `tel:+1${match[1]}-${match[2]}-${match[3]}`;

      if (shouldFormat) {
        if (match[0].length === 10 && match.length === 4) {
          text = `(${match[1]}) ${match[2]}-${match[3]}`;
        }
      }
    } else {
      href = href ?? `tel:${phone}`;
    }
  }

  const Tag = href ? 'a' : 'span';

  // Slightly adjust icon offset to account
  // for height of certain letters
  const shouldOffset = useMemo(() => {
    if (typeof text !== 'string' || /^[A-Z]/.test(text)) {
      return false;
    }

    return /^[gyqpj]/.test(text);
  }, [text]);

  return !text ? null : (
    <Tag
      href={href}
      className={classNames(styles.link, className, {
        [styles.offset]: shouldOffset
      })}
    >
      {icon}
      <span>{text}</span>
    </Tag>
  );
});
