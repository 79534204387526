import React from 'react';
import { H1 } from '@headline';
import Divider from '@components/Divider';
import * as styles from './PageHeadline.module.scss';
import classNames from 'classnames';

export default function PageHeadline({
  title,
  pretitle,
  className,
  isCentered = true,
  dividerMaxWidth,
  children
}) {
  if (typeof children === 'string') {
    children = children
      .trim()
      .split('\n')
      .filter(t => !!t.trim())
      .map((text, index) => <p key={index}>{text}</p>);
  }

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.alignLeft]: !isCentered
      })}
    >
      <H1 pretitle={pretitle}>{title}</H1>
      {children && <div className={styles.text}>{children}</div>}
      <Divider
        style={{ maxWidth: dividerMaxWidth }}
        className={styles.divider}
        strokeWidth={2.5}
        color="#CCCED2"
      />
    </div>
  );
}
