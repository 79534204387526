import React from 'react';
import Layout, { Section, Content } from '@layout';
import PageHeadline from '@components/PageHeadline';
import Person from './Person';
import { getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './About.module.scss';

export default function Home() {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "About/photos" }
        }
      ) {
        edges {
          node {
            id
            name
            ext
            childImageSharp {
              gatsbyImageData(
                width: 320
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges.map(({ node }) => {
    return {
      name: node.name,
      ext: node.ext,
      fileName: `${node.name}${node.ext}`,
      image: getImage(node)
    };
  });

  return (
    <Layout title="About Us">
      <Section className={styles.wrapper}>
        <Content>
          <PageHeadline
            title="Meet our team"
            pretitle="About Us"
          />
          {people.length && (
            <div className={styles.peopleWrapper}>
              {people.map((p, index) => (
                <Person
                  key={index}
                  images={images}
                  {...p}
                />
              ))}
            </div>
          )}
        </Content>
      </Section>
    </Layout>
  );
}

const people = [
  {
    name: 'Gregory Shinn',
    title: 'Registered Landscape Architect',
    email: 'gregs@grslandscapearchitects.com',
    phone: '(520) 909-4678',
    // photo: 'person.jpg',
    text: `
      Greg is a registered landscape architect with 35+ years experience in Southern Arizona. This Southern Arizona experience began with a Bachelor of Landscape Architecture from the University of Arizona in 1983. His experience includes thousands of completed projects across a broad base of project types. Greg’s commitment to service has resulted in his having many 25 year plus clients and client relationships. 
    `
  },
  {
    name: 'Linda Weaver',
    title: 'Designer and Draftsman',
    email: 'linda@grslandscapearchitects.com',
    // photo: 'person.jpg',
    text: `
      Linda is a project designer and production coordinator also with a Bache lor of Landscape Architecture form the University of Arizona. Linda has worked at GRS for 16 + years and currently works from the Phoenix satellite office. Linda’s experience includes many design intensive projects and complex Autocad plan sets. Linda is a project designer and production coordinator also with a Bachelor of Landscape Architecture form the University of Arizona. Linda has worked at GRS for 16 + years and currently works from the Phoenix satellite office. Linda’s experience includes many design intensive projects and complex Autocad plan sets.
    `
  }
];
