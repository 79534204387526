import React from 'react';
import { H2 } from '@headline';
import Corner from '@components/Corner';
import Divider from '@components/Divider';
import ContactLink from '@components/ContactLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './About.module.scss';

export default function Person({
  name,
  title,
  email,
  phone,
  text,
  images,
  photo,
  children
}) {
  let content = text ?? children;

  if (typeof content === 'string') {
    content = content
      .trim()
      .split('\n')
      .filter(t => !!t.trim())
      .map((text, index) => <p key={index}>{text}</p>);
  }

  const image =
    !photo || !images?.length
      ? null
      : images.find(
        ({ name, fileName }) => photo === name || photo === fileName
      )?.image;

  return (
    <React.Fragment>
      <div className={styles.person}>
        {image && (
          <div className={styles.photoWrapper}>
            <GatsbyImage
              image={image}
              alt=""
            />

            <Corner
              className={styles.corner}
              side="topRight"
              dashLong="27"
              dashShort="7"
              dashGap="7"
              dashOffset="17"
              strokeWidth={3.2}
            />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.info}>
              <H2>{name}</H2>
              {title && <p className={styles.title}>{title}</p>}
            </div>

            {(email || phone) && (
              <div className={styles.contact}>
                <ContactLink email={email} />
                <ContactLink phone={phone} />
              </div>
            )}
          </div>

          {content && (
            <React.Fragment>
              <Divider
                className={styles.contentDivider}
                strokeWidth={3}
                color="#e0e0e0"
              />
              <div className={styles.text}>{content}</div>
            </React.Fragment>
          )}
        </div>

        <Divider
          className={styles.sectionDivider}
          strokeWidth={3}
          color="#000"
        />
      </div>
    </React.Fragment>
  );
}
